import { pick } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import type { TrainingParticipant, TrainingSession } from 'models';
import type { ComponentType } from 'react';
import type { AppDispatch } from 'redux/actions/types';

import can from 'helpers/can';
import { guessTimeZone } from 'helpers/date';
import { __, n__ } from 'helpers/i18n';

import { put } from 'redux/actions/api';
import { post } from 'redux/actions/api';

import {
  Box,
  BoxList,
  BoxListItem,
  Button,
  Column,
  Columns,
  Icon,
  PullRight,
  Text,
} from 'components';

import ParticipantItem from './ParticipantItem';
import cancelInvitationsModal from './cancelInvitationsModal';
import sendInvitationsModal from './sendInvitationsModal';

type Props = {
  session: TrainingSession;
  refreshSession: () => Promise<void>;
};

type AfterConnectProps = {
  updateParticipant: (participant: TrainingParticipant) => Promise<void>;
  sendCalendarInvitations: () => Promise<void>;
  cancelCalendarInvitations: () => Promise<void>;
} & Props;

function Participants({
  session,
  refreshSession,
  updateParticipant,
  sendCalendarInvitations,
  cancelCalendarInvitations,
}: AfterConnectProps) {
  return (
    <React.Fragment>
      <Box>
        <PullRight style={{ gap: 16 }}>
          {can({ perform: 'cancel_invitations', on: session }) && (
            <Button
              color="secondary"
              onClick={() =>
                cancelInvitationsModal(session, cancelCalendarInvitations)
              }
            >
              <Icon name="cancel" additionalClassName="mr-1" />
              {__('Cancel the calendar invitation')}
            </Button>
          )}
          {can({ perform: 'send_invitations', on: session }) && (
            <Button
              color="primary"
              onClick={() =>
                sendInvitationsModal(session, sendCalendarInvitations)
              }
              disabled={!session.endDate || !session.startDate}
              disabledExplanation={__(
                'Add the training dates in the session details to send the invitation'
              )}
              style={{ marginLeft: 16 }}
            >
              <Icon name="send" additionalClassName="mr-1" />
              {__('Send the calendar invitation')}
            </Button>
          )}
        </PullRight>
        {session.autoInviteNewParticipants && (
          <PullRight style={{ marginTop: 8 }}>
            <div className="flex gap-2 items-center">
              <Icon color="success" name="check_circle" />
              <Text preset="13s7" color="light">
                {__(
                  'The new participants will be automatically invited to the session'
                )}
              </Text>
            </div>
          </PullRight>
        )}
        <BoxList style={{ marginTop: 16 }}>
          <BoxListItem>
            <Columns isMobile>
              <Column size={5}>
                <Text preset="14s6" color="light">
                  {__('Participant')}
                </Text>
              </Column>
              <Column size={4}>
                <Text preset="14s6" color="light">
                  {__('Funding')}
                </Text>
              </Column>
              <Column size={2}>
                <Text preset="14s6" color="light">
                  {__('Invitation')}
                </Text>
              </Column>
              <Column size={1} />
            </Columns>
          </BoxListItem>
          {session.participants?.map(participant => (
            <ParticipantItem
              key={participant.id}
              participant={participant}
              refreshSession={refreshSession}
              sessionName={session.name}
              saveParticipant={updateParticipant}
              periodSlug={session.period.slug}
            />
          ))}
          <BoxListItem style={{ paddingBottom: 16 }}>
            {n__(
              '%1 participant',
              '%1 participants',
              session.participants?.length || 0
            )}
          </BoxListItem>
        </BoxList>
      </Box>
    </React.Fragment>
  );
}

function mapDispatchToProps(dispatch: AppDispatch, { session }) {
  return {
    updateParticipant: async ({ id, fundingItems, ...participant }) =>
      await dispatch(
        put(`training/participants/${id}`, {
          trainingParticipant: {
            ...pick(participant, ['trainingCostCents']),
            fundingItemsAttributes: fundingItems.map(item => ({
              ...item,
              fundingSourceId: item.fundingSource.id,
            })),
          },
        })
      ),
    sendCalendarInvitations: () =>
      dispatch(
        post(
          `training/sessions/${session.id}/send_invitations`,
          {
            timezone: guessTimeZone(),
          },
          {
            successMessage: __(
              'The calendar invitation has been successfully sent'
            ),
          }
        )
      ),
    cancelCalendarInvitations: () =>
      dispatch(
        post(`training/sessions/${session.id}/cancel_invitations`, undefined, {
          successMessage: __(
            'The calendar invitation has been successfully cancelled'
          ),
        })
      ),
  };
}

export default connect(
  null,
  mapDispatchToProps
)(Participants) as ComponentType<Props>;
