import React, { Fragment, useState } from 'react';

import { Tag, UserFilterSegment } from 'models';

import { __ } from 'helpers/i18n';

import {
  ContentContainer,
  Label,
  Level,
  LevelLeft,
  PageHeader,
  PageTitle,
  Text,
} from 'components';

import TagPicker from 'scenes/components/TagPicker';
import UserFilter from 'scenes/components/UserFilter';

import PlannedActionsWithStats from './PlannedActionsWithStats';

const Index = () => {
  const [filteredTagLabels, setFilteredTagLabels] = useState<Array<Tag>>([]);
  const [userFilter, setUserFilter] = useState<UserFilterSegment | undefined>();
  const [shouldRefetchTags, setShouldRefetchTags] = useState(0);

  return (
    <Fragment>
      <PageTitle title={__('Actions plans')} />
      <PageHeader title={__('Actions plans')} />

      <ContentContainer>
        <Text>
          {__(
            'Actions are only visible to administrators and People Review responsibles. They are not displayed to employees.'
          )}
        </Text>

        <Level additionalClassName="my-4">
          <LevelLeft additionalClassName="space-x-2">
            <Label>{__('Type')}</Label>
            <TagPicker
              key={shouldRefetchTags}
              context="planned_action"
              selectedTags={filteredTagLabels}
              onTagsChange={setFilteredTagLabels}
              additionalClassName="w-64"
              placeholder={__('Select an action type')}
              preventCreation
            />
            <UserFilter segment={userFilter} onChange={setUserFilter} />
          </LevelLeft>
        </Level>

        <PlannedActionsWithStats
          tagLabels={filteredTagLabels.map(tag => tag.label)}
          userFilter={userFilter}
          refetchTags={() => setShouldRefetchTags(shouldRefetchTags + 1)}
        />
      </ContentContainer>
    </Fragment>
  );
};

export default Index;
