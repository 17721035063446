import React, { Fragment } from 'react';

import type { TrainingSession } from 'models';

import { FormErrors } from 'helpers/api';
import can from 'helpers/can';
import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import {
  Checkbox,
  Column,
  Columns,
  Field,
  FieldError,
  Input,
  Label,
  NumberInput,
  Text,
  Tooltip,
} from 'components';

import SimpleSelect from 'scenes/admin/Trainings/components/SimpleSelect';
import translatedSessionFormats from 'scenes/admin/Trainings/components/translatedSessionFormats';
import FloatInput from 'scenes/admin/components/FloatInput';
import MoneyInput from 'scenes/admin/components/MoneyInput';
import TrainingPeriodPicker from 'scenes/components/TrainingPeriodPicker';

import EndDatePicker from './EndDatePicker';
import StartDatePicker from './StartDatePicker';
import trainingSessionTypes from './trainingSessionTypes';

type Props = {
  session: TrainingSession;
  setSession: (session: TrainingSession) => void;
  onChange: (session?: TrainingSession) => void;
  errors: FormErrors;
};

const EditableFields = ({ session, setSession, onChange, errors }: Props) => {
  const organization = useOrganization();
  const formDisabled = !can({ perform: 'update', on: session });
  const canUpdateLocation = can({
    perform: 'update_dates_and_location',
    on: session,
  });

  const newTrainingSessionFieldsEnabled = organization.featureFlags.includes(
    'newTrainingSessionFields'
  );

  const locationDisabledContent = canUpdateLocation
    ? null
    : __(
        'Dates and location of the training session cannot be edited once the calendar invitation has been sent'
      );

  return (
    <Fragment>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-8">
        <Field>
          <Label>{__('Training name')}</Label>
          <Input
            value={session.name || ''}
            placeholder={__('Add training name')}
            onBlur={() => onChange()}
            onChange={name => setSession({ ...session, name })}
            disabled={formDisabled}
            testClassName="test-name-field"
          />
          <FieldError>{errors.name}</FieldError>
        </Field>
        <Field>
          <Label>{__('Training organization')}</Label>
          <Input
            value={session.trainingOrganization || ''}
            onBlur={() => onChange()}
            onChange={trainingOrganization =>
              setSession({
                ...session,
                trainingOrganization,
              })
            }
            disabled={formDisabled}
          />
          <FieldError>{errors.trainingOrganization}</FieldError>
        </Field>
        {newTrainingSessionFieldsEnabled && (
          <Field>
            <Checkbox
              additionalClassName="mr-4"
              isChecked={session.certifying}
              onChange={() =>
                onChange({ ...session, certifying: !session.certifying })
              }
              label={__('This training leads to certification')}
            />
          </Field>
        )}
      </div>
      <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-8">
        <div className="grid grid-cols-2 gap-x-8">
          <StartDatePicker
            session={session}
            onChange={onChange}
            errors={errors}
            disabled={formDisabled}
          />
          <EndDatePicker
            session={session}
            onChange={onChange}
            errors={errors}
            disabled={formDisabled}
          />
        </div>
        <Field>
          <Label>{__('Number of hours')}</Label>
          <FloatInput
            value={
              session.durationInHours ? Number(session.durationInHours) : null
            }
            placeholder={__('Enter the number of hours')}
            onInputChange={(durationInHours: number | null) => {
              onChange({
                ...session,
                durationInHours: `${durationInHours || ''}`,
              });
            }}
            isDisabled={formDisabled}
            saveOnlyOnBlur
          />
          <FieldError>{errors.durationInHours}</FieldError>
        </Field>
      </div>
      <Columns additionalClassName="mt-0 mb-0">
        <Column size={newTrainingSessionFieldsEnabled ? 3 : 6}>
          <Field>
            <Label>{__('Type')}</Label>
            <SimpleSelect
              value={session.trainingType}
              // @ts-expect-error: intra and inter values will be removed once the feature flag is globally enabled
              values={trainingSessionTypes(newTrainingSessionFieldsEnabled)}
              placeholder={__('Select a type')}
              onChange={trainingType => {
                onChange({
                  ...session,
                  trainingType,
                });
              }}
              isDisabled={formDisabled}
            />
            <FieldError>{errors.trainingType}</FieldError>
          </Field>
        </Column>
        <Column size={newTrainingSessionFieldsEnabled ? 5 : 6}>
          <Field>
            <Label>{__('Format')}</Label>
            <SimpleSelect
              value={session.trainingFormat}
              values={translatedSessionFormats()}
              placeholder={__('Select a format')}
              onChange={trainingFormat => {
                onChange({
                  ...session,
                  trainingFormat,
                });
              }}
              isDisabled={formDisabled}
            />
            <FieldError>{errors.trainingFormat}</FieldError>
          </Field>
        </Column>
        {newTrainingSessionFieldsEnabled && (
          <Column size={4}>
            <Field>
              <Label>{__('Number of available seats')}</Label>
              <div className="grid grid-cols-2 gap-x-8">
                <div className="flex items-center">
                  <Text additionalClassName="mr-2"> {__('Minimum')}</Text>
                  <NumberInput
                    value={
                      session.minAvailableSeats === null
                        ? undefined
                        : session.minAvailableSeats
                    }
                    onChange={value =>
                      setSession({
                        ...session,
                        minAvailableSeats: value || null,
                      })
                    }
                    onBlur={() => onChange()}
                  />
                </div>
                <div className="flex items-center">
                  <Text additionalClassName="mr-2"> {__('Maximum')}</Text>
                  <NumberInput
                    style={{ MozAppearance: 'textfield' }}
                    value={
                      session.maxAvailableSeats === null
                        ? undefined
                        : session.maxAvailableSeats
                    }
                    onChange={value =>
                      setSession({
                        ...session,
                        maxAvailableSeats: value || null,
                      })
                    }
                    onBlur={() => onChange()}
                  />
                </div>
              </div>
              <FieldError>{errors.minAvailableSeats}</FieldError>
              <FieldError>{errors.maxAvailableSeats}</FieldError>
            </Field>
          </Column>
        )}
      </Columns>

      <Field>
        <Label>{__('Location of the training')}</Label>
        <Tooltip content={locationDisabledContent}>
          <Input
            value={session.location || ''}
            onBlur={() => onChange()}
            onChange={location => {
              setSession({
                ...session,
                location,
              });
            }}
            disabled={formDisabled || !canUpdateLocation}
          />
        </Tooltip>
        <FieldError>{errors.location}</FieldError>
      </Field>
      <div className="grid mt-4 grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-8">
        <Field>
          <Label>{__('Period')}</Label>
          <TrainingPeriodPicker
            renderingStyle="select"
            currentPeriodSlug={session.period.slug}
            onChange={trainingPeriod =>
              onChange({ ...session, period: trainingPeriod })
            }
          />
        </Field>
        <Field>
          <Label>{__('Total budget')}</Label>
          <MoneyInput
            amount={session.totalBudgetCents}
            placeholder={__('Enter the total budget')}
            onAmountChange={totalBudgetCents => {
              onChange({
                ...session,
                totalBudgetCents,
              });
            }}
            currency={session.totalBudgetCurrency}
            isDisabled={formDisabled}
            saveOnlyOnBlur
          />
          <FieldError>{errors.totalBudget}</FieldError>
        </Field>
      </div>
    </Fragment>
  );
};

export default EditableFields;
