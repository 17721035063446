import React from 'react';

import type { TrainingParticipant } from 'models';

import { FormErrors } from 'helpers/api';
import { __ } from 'helpers/i18n';

import {
  Button,
  Centered,
  Divider,
  Field,
  FieldError,
  Icon,
  Label,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import MoneyInput from 'scenes/admin/components/MoneyInput';

import FundingSection from './FundingSection';

type Props = {
  onClose: () => void;
  participant: TrainingParticipant;
  periodSlug: string;
  onSubmit: (participant: TrainingParticipant) => Promise<void>;
  errors: FormErrors;
};

function AddBudgetItemForParticipantModal({
  onClose,
  participant: participantFromProps,
  periodSlug,
  onSubmit,
  errors,
}: Props) {
  const [participant, setParticipant] = React.useState(participantFromProps);

  const handleParticipantChange = (params: Partial<TrainingParticipant>) => {
    setParticipant({ ...participant, ...params });
  };

  const onSubmitChanges = async () => {
    await onSubmit(participant);
  };

  return (
    <ModalCard
      isActive
      isLarge
      refreshContentOnOpening
      onClose={onClose}
      testClassName="test-training-participant-edit-modal"
    >
      <ModalCardHead>
        <ModalCardTitle>
          {__('Budget for %1 training', participant.user.fullName)}
        </ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <Divider style={{ marginTop: 0 }} />
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Field>
              <Label>{__('Individual training cost')}</Label>
              <MoneyInput
                amount={participant.trainingCostCents}
                placeholder={__('Enter the overall cost')}
                onAmountChange={trainingCostCents =>
                  handleParticipantChange({ trainingCostCents })
                }
                currency={participant.trainingCostCurrency}
              />
              <FieldError>{errors.trainingCost}</FieldError>
            </Field>
          </div>
          <div className="col-span-2">
            <Label>{__('Funding of the session')}</Label>
            <FundingSection
              periodSlug={periodSlug}
              fundingItems={participant.fundingItems}
              errors={errors}
              onChange={fundingItems => {
                return handleParticipantChange({
                  fundingItems,
                });
              }}
            />
          </div>
        </div>
      </ModalCardBody>
      <ModalCardFooter>
        <Centered>
          <div style={{ marginTop: 16 }}>
            <Button onClick={onClose} color="secondary">
              {__('Cancel')}
            </Button>
            <Button onClick={() => onSubmitChanges()} color="primary">
              <Icon style={{ marginRight: 8 }} name="save" />
              {__('Save')}
            </Button>
          </div>
        </Centered>
      </ModalCardFooter>
    </ModalCard>
  );
}

export default AddBudgetItemForParticipantModal;
