import React from 'react';

import type { User } from 'models';

import { __ } from 'helpers/i18n';

import UserListWithPagination from 'scenes/components/user/UserListWithPagination';

type Props = {
  activeUser: User;
};

const IndirectReports = ({ activeUser }: Props) => {
  return (
    <div>
      <h2 className="subtitle">{__('Indirect team')}</h2>
      <UserListWithPagination
        paginationType="state"
        fetchPath={`users/${activeUser.id}/indirect_reports`}
      />
    </div>
  );
};

export default IndirectReports;
