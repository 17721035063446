import React from 'react';
import { compose } from 'redux';

import type { FundingSource, ParticipantFundingItem } from 'models';

import NestedResourceWrapper from 'helpers/NestedResourceWrapper';
import { FormErrors } from 'helpers/api';
import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Button, FetchContainer, FieldError } from 'components';

import FundingItem from './FundingItem';

type NewFundingItem = {
  amountCents: number | undefined | null;
  amountCurrency: string;
  fundingSource: FundingSource | undefined | null;
};

type Props = {
  periodSlug: string;
  fundingItems: Array<ParticipantFundingItem>;
  errors: FormErrors;
  onChange: (params: Array<ParticipantFundingItem>) => void;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    fundingSources: Array<FundingSource>;
  };

const SessionFundingSection = ({
  fundingItems,
  errors,
  onChange,
  isFetching,
  hasError,
  fundingSources,
}: AfterDataLoaderProps) => {
  const organization = useOrganization();
  const [fundingItemCreationIsActive, setFundingItemCreationIsActive] =
    React.useState(false);

  const itemsList = new NestedResourceWrapper<ParticipantFundingItem>({
    items: fundingItems,
    afterChange: items => {
      setFundingItemCreationIsActive(false);
      onChange(items.map(item => ({ ...item.data })));
    },
  });

  const emptyFundingItem = {
    amountCents: null,
    amountCurrency: organization.settings.defaultCurrency,
    fundingSource: null,
  } as NewFundingItem;

  const disabledFundingSources = itemsList
    .notDeleted()
    .map(item => item.fundingSource);

  return (
    <FetchContainer
      loadingStyle="alone"
      isFetching={isFetching}
      hasError={hasError}
      render={() => (
        <div
          style={{ marginBottom: '16px' }}
          className="test-training-request-funding-section"
        >
          {itemsList.map((fundingItem, position) => (
            <FundingItem
              key={position}
              fundingItem={fundingItem}
              fundingSources={fundingSources}
              disabledFundingSources={disabledFundingSources}
              onUpdate={newFundingItem =>
                itemsList.onUpdate(fundingItem, newFundingItem)
              }
              onDelete={() => itemsList.onDelete(fundingItem)}
            />
          ))}
          {fundingItemCreationIsActive && (
            <FundingItem
              key={0}
              fundingItem={emptyFundingItem}
              fundingSources={fundingSources}
              disabledFundingSources={disabledFundingSources}
              onUpdate={newFundingItem => itemsList.onCreate(newFundingItem)}
              onDelete={() => setFundingItemCreationIsActive(false)}
            />
          )}
          <Button
            testClassName="test-funding-item-form-button mt-2"
            color="secondary"
            onClick={() => setFundingItemCreationIsActive(true)}
            disabled={itemsList.notDeleted().length >= fundingSources.length}
          >
            <span>{__('Add a funding source')}</span>
          </Button>
          <FieldError>{errors.fundingItemsAmount}</FieldError>
        </div>
      )}
    />
  );
};

export default compose(
  newDataLoader({
    fetch: ({ periodSlug }: AfterDataLoaderProps) =>
      get(`training/periods/${periodSlug}/funding_sources`, {
        active: true,
      }),
    hydrate: {
      fundingSources: {},
    },
  })
)(SessionFundingSection) as React.ComponentType<Props>;
