import React, { ReactNode } from 'react';

import { __ } from 'helpers/i18n';
import {
  pathToTrainingPeriodRequests,
  pathToTrainingSessionIndex,
  pathToTrainings,
} from 'helpers/paths';

import { PageHeader as DefaultPageHeader } from 'components';

type Props = {
  periodSlug?: string;
  actions: ReactNode;
};

const PageHeader = ({ periodSlug, actions }: Props) => {
  const tabItems = [
    {
      label: ' ' + __('Training requests'),
      to: periodSlug
        ? pathToTrainingPeriodRequests(periodSlug)
        : pathToTrainings(),
    },
    {
      label: ' ' + __('Training sessions'),
      to: periodSlug
        ? pathToTrainingSessionIndex(periodSlug)
        : pathToTrainings(),
    },
  ];
  return (
    <DefaultPageHeader
      title={__('Trainings')}
      actions={actions}
      tabItems={tabItems}
    />
  );
};

export default PageHeader;
