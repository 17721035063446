import React, { Fragment } from 'react';

import colors from 'styles/colors';

import { __ } from 'helpers/i18n';
import { pathToHome } from 'helpers/paths';

import {
  Button,
  EmptyStateWithIcon,
  Icon,
  Link,
  PageHeader,
  PageTitle,
} from 'components';

const Catalog = () => {
  const action = (
    <Button color="primary" onClick={() => {}}>
      <Icon name="add" additionalClassName={'mr-2'} />
      {__('Submit an off-catalog request')}
    </Button>
  );

  const offCatalogRequestLink = (
    <Link to={pathToHome()} style={{ color: colors.blueElevo }}>
      {__('off-catalog request')}
    </Link>
  );

  const emptyStateDescription = __(
    'The training catalog is currently empty. In the meantime, you can submit an %1 for a specific training.',
    offCatalogRequestLink
  );

  return (
    <Fragment>
      <PageTitle title={[__('Trainings'), __('Catalog')]} />
      <PageHeader title={__('Explore the training catalog')} actions={action} />
      <EmptyStateWithIcon
        iconName="school"
        title={__('Training catalog')}
        description={emptyStateDescription}
        additionalClassName="m-4"
      />
    </Fragment>
  );
};

export default Catalog;
