import { isEmpty } from 'lodash';
import * as React from 'react';

import type { User } from 'models';

import { handleFormErrors } from 'helpers/api';
import { __ } from 'helpers/i18n';

import {
  Button,
  Field,
  FieldError,
  Flex,
  Icon,
  Input,
  Label,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Textarea,
} from 'components';

import PriorityPicker from 'scenes/components/PriorityPicker';
import TrainingPeriodPicker from 'scenes/components/TrainingPeriodPicker';

import CollaboratorsChoiceBlock from './CollaboratorsChoiceBlock';
import { CreateTrainingRequestFormObject } from './types';

type Props = {
  currentTrainingPeriodSlug: string;
  concernedUser?: User;
  onSubmit: (trainingRequest: CreateTrainingRequestFormObject) => Promise<void>;
  onClose: () => void;
};

export default function CreateContent({
  currentTrainingPeriodSlug,
  concernedUser,
  onSubmit,
  onClose,
}: Props) {
  const [errors, setErrors] = React.useState<
    Partial<CreateTrainingRequestFormObject>
  >({});
  const [trainingRequest, setTrainingRequest] =
    React.useState<CreateTrainingRequestFormObject>({
      title: '',
      description: '',
      // @ts-ignore TSFIXME: Fix strictNullChecks error
      trainee: concernedUser,
      priority: 'medium',
      periodSlug: currentTrainingPeriodSlug,
      traineeIds: new Set<string>(),
      collaboratorsChoice: 'single',
    });
  const [sendRequestLabel, setSendRequestLabel] = React.useState(
    __('Send request')
  );

  const isTrainingRequestValid = () => {
    return (
      !isEmpty(trainingRequest.title) &&
      !isEmpty(trainingRequest.description) &&
      ((trainingRequest.collaboratorsChoice === 'single' &&
        !isEmpty(trainingRequest.trainee)) ||
        (trainingRequest.collaboratorsChoice === 'multiple' &&
          trainingRequest.traineeIds.size !== 0)) &&
      !isEmpty(trainingRequest.priority)
    );
  };

  const handleSubmit = () =>
    handleFormErrors(() => onSubmit(trainingRequest), setErrors);

  return (
    <React.Fragment>
      <ModalCardHead onClose={onClose}>
        <ModalCardTitle>{__('Create training request')}</ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody style={{ paddingBottom: 16 }}>
        <Field>
          <Label>{__('Request summary')}</Label>
          <Input
            // @ts-ignore TSFIXME: Fix strictNullChecks error
            value={trainingRequest.title}
            placeholder={__('ex: Learn the basics of Microsoft Excel')}
            onChange={title =>
              setTrainingRequest({ ...trainingRequest, title })
            }
          />
          <FieldError>{errors.title}</FieldError>
        </Field>
        <Field>
          <Label>{__('Additional information about the request')}</Label>
          <Textarea
            value={trainingRequest.description}
            placeholder={__(
              '- What is the purpose of this request?\n' +
                '- Why is it important for your employee’s development?\n' +
                'You can suggest one or several training organizations'
            )}
            rows={5}
            maxRows={10}
            onChange={description =>
              setTrainingRequest({ ...trainingRequest, description })
            }
          />
          <FieldError>{errors.description}</FieldError>
        </Field>
        <Field>
          <CollaboratorsChoiceBlock
            concernedUser={concernedUser}
            trainingRequest={trainingRequest}
            setTrainingRequest={setTrainingRequest}
            errors={errors}
            setSendRequestLabel={setSendRequestLabel}
            collaboratorsChoice={trainingRequest.collaboratorsChoice}
            setCollaboratorsChoice={collaboratorsChoice =>
              setTrainingRequest({
                ...trainingRequest,
                collaboratorsChoice,
              })
            }
          />
        </Field>
        <Flex direction="row" style={{ flexWrap: 'wrap', gap: '0 32px' }}>
          <Field style={{ flex: '1 1 200px', minWidth: 200 }}>
            <Label>{__('Request priority (creation modal)')}</Label>
            <PriorityPicker
              value={trainingRequest.priority}
              onChange={priority =>
                setTrainingRequest({ ...trainingRequest, priority })
              }
            />
            <FieldError>{errors.priority}</FieldError>
          </Field>
          <Field style={{ flex: '1 1 200px', minWidth: 200 }}>
            <Label>{__('Period')}</Label>
            <TrainingPeriodPicker
              renderingStyle="select"
              currentPeriodSlug={trainingRequest.periodSlug}
              onChange={period =>
                setTrainingRequest({
                  ...trainingRequest,
                  periodSlug: period.slug,
                })
              }
            />
          </Field>
        </Flex>
      </ModalCardBody>
      <ModalCardFooter>
        <Button onClick={onClose} color="secondary">
          {__('Cancel')}
        </Button>
        <Button
          disabled={!isTrainingRequestValid()}
          onClick={handleSubmit}
          color="primary"
        >
          <Icon style={{ marginRight: 8 }} name="send" />
          {sendRequestLabel}
        </Button>
      </ModalCardFooter>
    </React.Fragment>
  );
}
