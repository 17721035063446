import React, { useState } from 'react';

import type { UserFilterSegment } from 'models';

import { history } from 'config/history';

import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import queryString from 'helpers/queryString';

import { parsePaginationParams } from 'lib/dataLoader/pagination/paginationParams';

import {
  ContentContainer,
  DesignSystem,
  FeatureFlagged,
  Level,
  LevelRight,
  PageHeader,
  StrictlySanitizedHtml,
  Text,
} from 'components';

import UserFilter from 'scenes/components/UserFilter';

import ExportButton from './ExportButton';
import UserObjectivesListWithPagination from './UserObjectivesListWithPagination';
import UserObjectivesStats from './UserObjectivesStats';

const ObjectiveDashboard = () => {
  const activeUser = useActiveUser();
  const paginationParams = parsePaginationParams(
    queryString.parse(window.location.search)
  );

  const [userFilter, setUserFilter] = useState<UserFilterSegment | undefined>(
    paginationParams.userFilter !== undefined
      ? paginationParams.userFilter
      : {
          filterLabel: __('Direct reports of'),
          filterType: 'direct_reports',
          label: activeUser.fullName,
          value: activeUser.id,
        }
  );

  const onUserFilterChange = (newUserFilter: UserFilterSegment | undefined) => {
    setUserFilter(newUserFilter);

    history.replace(
      history.location.pathname +
        '?' +
        queryString.stringify({
          ...paginationParams,
          userFilter: newUserFilter,
          page: 1,
        })
    );
  };

  return (
    <DesignSystem version={2}>
      <PageHeader
        title={__('Objectives follow-up')}
        actions={<ExportButton />}
      />
      <ContentContainer>
        <div className="!mb-4">
          <FeatureFlagged
            flag="draftObjectives"
            alternativeChildren={
              <Text>
                {__(
                  'Only the objectives of the current period will be visible on this page and in the export.'
                )}
              </Text>
            }
          >
            <Text>
              <StrictlySanitizedHtml
                html={__(
                  'Only the <b>published</b> objectives of the current period will be visible on this page and in the export.'
                )}
              />
            </Text>
          </FeatureFlagged>
        </div>

        <Level>
          <LevelRight>
            <UserFilter segment={userFilter} onChange={onUserFilterChange} />
          </LevelRight>
        </Level>

        <UserObjectivesStats userFilter={userFilter} />

        <UserObjectivesListWithPagination
          defaultPaginationParams={{ userFilter }}
          fetchPath="user_objective_periods/dashboard"
          paginationType="url"
          userFilterSegment={userFilter}
        />
      </ContentContainer>
    </DesignSystem>
  );
};

export default ObjectiveDashboard;
