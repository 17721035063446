import React, { Fragment, useContext, useState } from 'react';

import { AdminRole, AdminRoleName, AdminUser, Entity, User } from 'models';

import { useOrganization } from 'helpers/hooks';
import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';

import { post } from 'redux/actions/api';

import {
  Button,
  Icon,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
  Tooltip,
} from 'components';

import UserAvatar from 'scenes/components/UserAvatar';
import UserPicker from 'scenes/components/UserPicker';

import { DataContext } from '..';
import displayableRoleName from '../helpers/displayableRoleName';
import uniqueRolesCount from '../helpers/uniqueRolesCount';
import RoleNameAndEntitySelects from './RoleNameAndEntitySelects';
import fakeAllUserEntity from './fakeAllUserEntity';
import groupRoles from './groupRoles';

type Props = {
  user?: AdminUser;
  onClose: () => void;
};

export type RolesWithEntitiesType = Partial<Record<AdminRoleName, Entity[]>>;

const RolesManagementModal = ({ user, onClose }: Props) => {
  const organization = useOrganization();
  const multiLevelAdminEnabled = organization.plan.multiLevelAdminEnabled;

  const dispatch = useAppDispatch();
  const [selectedUsers, setSelectedUsers] = useState<User[]>(
    !!user ? [user] : []
  );
  const { setShouldRefetchAdmins } = useContext(DataContext);

  // Deprecation EA-7578: Use `user.roles` directly (see MR !7641)
  const userRoles = user
    ? [
        ...user.roles.map(role => ({
          ...role,
          name:
            role.name === 'campaigns_supervisor'
              ? 'campaigns_responsible'
              : role.name,
        })),
      ]
    : [];

  const [selectedRolesWithEntities, setSelectedRolesWithEntities] =
    useState<RolesWithEntitiesType>(groupRoles(userRoles));

  const [showEmptySelect, setShowEmptySelect] = useState<boolean>(
    Object.keys(selectedRolesWithEntities).length === 0
  );

  const roles = ['admin'] as Array<AdminRole['name']>;

  if (organization.featureFlags.includes('newAdministrationRoles')) {
    roles.push('performance_admin', 'training_admin', 'campaigns_responsible');
  }

  const roleOptions = roles.map(role => ({
    label: displayableRoleName(role),
    value: role,
    isDisabled: false,
  }));

  const isAddRoleButtonDisabled =
    Object.keys(selectedRolesWithEntities).length === 0 ||
    Object.keys(selectedRolesWithEntities).length === roles.length ||
    showEmptySelect;

  const isAssignButtonDisabled =
    selectedUsers.length === 0 ||
    (Object.values(selectedRolesWithEntities) as Array<Array<Entity>>).filter(
      values => values.length === 0
    ).length > 0 ||
    showEmptySelect;

  const assignRoles = async () => {
    const formattedRoles: Array<{
      name: AdminRoleName;
      resourceType: string;
      resourceId: string;
    }> = [];

    for (const roleName in selectedRolesWithEntities) {
      const selectedEntities = selectedRolesWithEntities[roleName];

      const hasFakeAllUsersEntity = selectedEntities.some(
        entity => entity.id === fakeAllUserEntity().id
      );

      if (hasFakeAllUsersEntity) {
        formattedRoles.push({
          name: roleName as AdminRoleName,
          resourceType: 'Organization',
          resourceId: organization.id,
        });
      } else {
        selectedEntities.forEach(entity => {
          formattedRoles.push({
            name: roleName as AdminRoleName,
            resourceType: 'Entity',
            resourceId: entity.id,
          });
        });
      }
    }

    await dispatch(
      post('admin_roles/bulk_assign', {
        userIds: selectedUsers.map(user => user.id),
        roles: formattedRoles,
      })
    );
    onClose();
    setShouldRefetchAdmins(true);
  };

  const roleNameAndEntitySelectFor = (roleName: AdminRoleName | null) => {
    return (
      <RoleNameAndEntitySelects
        selectedRoleName={roleName}
        selectedRolesWithEntities={selectedRolesWithEntities}
        roleOptions={roleOptions}
        multiLevelAdminEnabled={multiLevelAdminEnabled}
        showEmptySelect={showEmptySelect}
        setSelectedRolesWithEntities={setSelectedRolesWithEntities}
        setShowEmptySelect={setShowEmptySelect}
      />
    );
  };

  return (
    <ModalCard onClose={onClose} isActive isBig>
      <ModalCardHead>
        <ModalCardTitle>
          {!!user
            ? n__(
                "Update %2's role",
                "Update %2's roles",
                uniqueRolesCount(user.roles),
                user.fullName
              )
            : __('Assign a role to one or more users')}
        </ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <div className="mb-2">
          <div className="mb-4">
            {!!user ? (
              <UserAvatar withJobTitle user={user} />
            ) : (
              <Fragment>
                <Text preset="14bs6" additionalClassName="block mb-2">
                  {__('Select one or more users')}
                </Text>
                <UserPicker
                  value={selectedUsers}
                  isMulti
                  inModal
                  isClearable
                  placeholder={__('Select one or more users')}
                  onChange={users =>
                    Array.isArray(users) && setSelectedUsers(users)
                  }
                  additionalClassName="w-full"
                  fetchParams={{ not_admin: true }}
                />
              </Fragment>
            )}
          </div>
          <div className="mb-2">
            <Text preset="14bs6">
              {n__(
                'Assign one or more roles to the selected user',
                'Assign one or more roles to the selected users',
                selectedUsers.length
              )}
              {multiLevelAdminEnabled && (
                <Tooltip
                  content={__(
                    'Select a role, then the entities to which it will be applied'
                  )}
                  triggerAdditionalClassName="ml-1"
                >
                  <Icon name="info" />
                </Tooltip>
              )}
            </Text>
          </div>
          <div>
            {(
              Object.keys(selectedRolesWithEntities) as Array<AdminRoleName>
            ).map(roleName => roleNameAndEntitySelectFor(roleName))}
          </div>
          {showEmptySelect && roleNameAndEntitySelectFor(null)}

          {roles.length > 1 && (
            <Button
              size="small"
              color="secondary"
              additionalClassName="mt-2"
              onClick={() => setShowEmptySelect(true)}
              disabled={isAddRoleButtonDisabled}
            >
              {__('Add a role')}
            </Button>
          )}
        </div>
      </ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Close')}
        </Button>
        <Button
          color="primary"
          onClick={assignRoles}
          disabled={isAssignButtonDisabled}
        >
          {user
            ? n__(
                'Update %1 role',
                'Update %1 roles',
                Object.keys(selectedRolesWithEntities).length
              )
            : n__(
                'Assign',
                'Assign %1 roles',
                Object.keys(selectedRolesWithEntities).length
              )}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default RolesManagementModal;
