const currencies = Object.freeze([
  'GBP',
  'AUD',
  'CAD',
  'CHE',
  'CHF',
  'CHW',
  'CNY',
  'DKK',
  'EUR',
  'HUF',
  'INR',
  'JPY',
  'MXN',
  'MXV',
  'NOK',
  'PLN',
  'RON',
  'RSD',
  'SEK',
  'UAH',
  'USD',
  'ZAR',
]);

export default currencies;
