import React from 'react';

import type { RouteComponentProps } from 'react-router-dom';

import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToTrainings } from 'helpers/paths';

import { DesignSystem, PageTitle, Redirect, Route, Switch } from 'components';

import SimbelTrainingRoutes from 'scenes/components/simbel/SimbelTrainingRoutes';

import Landing from './Landing';
import PeriodCreator from './PeriodCreator';
import PeriodRedirector from './PeriodRedirector';
import PeriodRequests from './PeriodRequests';
import SessionManagement from './sessions/SessionManagement';
import Sessions from './sessions/Sessions';

function TrainingRoutes(props: RouteComponentProps) {
  const { path } = props.match;

  return (
    <DesignSystem version={2}>
      <PageTitle title={[__('Trainings'), __('Admin')]} />

      <Switch>
        <Route path={`${path}/sessions/:id`} component={SessionManagement} />

        <Route>
          <Switch>
            <Route exact path={`${path}`} component={PeriodRedirector} />

            <Route path={`${path}/periods/new`} component={PeriodCreator} />
            <Route path={`${path}/landing`} component={Landing} />
            <Route
              path={`${path}/:periodSlug/requests`}
              component={PeriodRequests}
            />

            <Route path={`${path}/:periodSlug/sessions`} component={Sessions} />

            <Redirect to={pathToTrainings()} />
          </Switch>
        </Route>
      </Switch>
    </DesignSystem>
  );
}

export default function Routes(props: RouteComponentProps) {
  const organization = useOrganization();

  if (organization.isSimbelIntegrationActive) {
    return <SimbelTrainingRoutes {...props} />;
  } else {
    return <TrainingRoutes {...props} />;
  }
}
