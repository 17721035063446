import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Redirect, Route, Switch } from 'components';

import Catalog from './Catalog';

export default function Routes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/catalog`} exact component={Catalog} />

      <Redirect to={`${path}/catalog`} />
    </Switch>
  );
}
