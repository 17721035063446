import React, { Fragment, useState } from 'react';

import { PeopleUserReviewPlannedAction } from 'models';

import can from 'helpers/can';
import classNames from 'helpers/classNames';
import { dateFromDefaultDateString } from 'helpers/date';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToPeopleUserReview } from 'helpers/paths';
import confirmAsync from 'helpers/react/confirmAsync';

import { htmlSuccessNotice } from 'redux/actions';
import { put } from 'redux/actions/api';

import {
  BoxListItem,
  Button,
  Column,
  Columns,
  Date as DateComponent,
  Icon,
  StrictlySanitizedHtml,
  Tag,
  Text,
  Tooltip,
} from 'components';

import UserAvatar from 'scenes/components/UserAvatar';
import StatusPicker from 'scenes/components/plannedActions/StatusPicker';

import useUpdatePlannedAction from '../hooks/useUpdatePlannedAction';
import UpdatePeopleReviewActionModal from './UpdatePeopleReviewActionModal';

type Props = {
  peopleReviewAction: PeopleUserReviewPlannedAction;
  compactDisplay: boolean;
  refetchData: () => Promise<any>;
};

const PeopleReviewActionListItem = ({
  compactDisplay,
  peopleReviewAction,
  refetchData,
}: Props) => {
  const dispatch = useAppDispatch();
  const [updateModal, setUpdateModal] = useState({ action: null });

  const shouldRenderActionAsLate =
    peopleReviewAction.status !== 'done' &&
    peopleReviewAction.dueDate &&
    // @ts-ignore TSFIXME: Fix strictNullChecks error
    dateFromDefaultDateString(peopleReviewAction.dueDate) < new Date();

  const canUpdateAction = can({ perform: 'update', on: peopleReviewAction });
  const handleAfterUpdate = () => {
    setUpdateModal({ action: null });
    refetchData();
  };
  const onLinkClick = canUpdateAction
    ? // @ts-ignore TSFIXME: Fix strictNullChecks error
      () => setUpdateModal({ action: peopleReviewAction })
    : undefined;

  const handleArchive = async (
    peopleReviewAction: PeopleUserReviewPlannedAction
  ) => {
    await dispatch(
      put(`planned/actions/${peopleReviewAction.id}`, { archived: true })
    );

    refetchData();

    dispatch(
      htmlSuccessNotice(
        __(
          'The action for %1 has been successfully deleted.',
          peopleReviewAction.user.fullName
        )
      )
    );
  };
  const onActionDelete = (peopleReviewAction: PeopleUserReviewPlannedAction) =>
    confirmAsync(
      __('Delete the action?'),
      <Fragment>
        <StrictlySanitizedHtml
          html={__(
            'This will delete the action for <b>%1</b>.',
            peopleReviewAction.user.fullName
          )}
        />
        <p className="my-2">{__('Are you sure you want to delete it?')}</p>
        <Text color="danger">
          <Icon name="warning" additionalClassName="mr-2" />
          {__('This action cannot be undone')}
        </Text>
      </Fragment>,
      {
        confirmLabel: __('Delete the action'),
        isDanger: true,
        onConfirm: () => handleArchive(peopleReviewAction),
      }
    );

  const { handleSubmit } = useUpdatePlannedAction(peopleReviewAction, () =>
    refetchData()
  );

  return (
    <React.Fragment>
      <BoxListItem>
        <Columns contentVerticallyAligned>
          <Column ellipsisOnLineOverflow>
            <Tooltip
              content={
                <Text size={7} additionalClassName="info-tooltip-content">
                  {peopleReviewAction.title}
                </Text>
              }
            >
              <Text onLinkClick={onLinkClick}>{peopleReviewAction.title}</Text>
            </Tooltip>
          </Column>
          {!compactDisplay && (
            <React.Fragment>
              <Column size={2}>
                {peopleReviewAction.tags.map(tag => (
                  <Tag key={tag.id} additionalClassName="my-1 mr-1 max-w-full">
                    <span className="is-text-overflow-ellipsis">
                      {tag.label}
                    </span>
                  </Tag>
                ))}
              </Column>
              <Column size={2}>
                <UserAvatar
                  user={peopleReviewAction.user}
                  withJobTitle
                  link={pathToPeopleUserReview(peopleReviewAction.resource.id)}
                />
              </Column>
            </React.Fragment>
          )}
          <Column size={2}>
            <DateComponent
              value={peopleReviewAction.dueDate}
              format={compactDisplay ? 'L' : 'LL'}
              // @ts-ignore TSFIXME: Fix strictNullChecks error
              additionalClassName={classNames({
                'has-text-danger': shouldRenderActionAsLate,
              })}
            />
          </Column>
          <Column size={compactDisplay ? 3 : 2}>
            <StatusPicker
              value={peopleReviewAction.status}
              onChange={status => {
                handleSubmit({ status });
              }}
            />
          </Column>
          <Column size={1} contentIsPulledRight>
            <Button
              hasIconOnly
              additionalClassName="border-none bg-transparent"
              onClick={() => onActionDelete(peopleReviewAction)}
            >
              <Icon size="large" name="delete" />
            </Button>
          </Column>
        </Columns>
      </BoxListItem>

      {!!updateModal.action && (
        <UpdatePeopleReviewActionModal
          plannedAction={updateModal.action}
          onClose={() => setUpdateModal({ action: null })}
          afterUpdate={handleAfterUpdate}
        />
      )}
    </React.Fragment>
  );
};

export default PeopleReviewActionListItem;
