import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { compose } from 'redux';

import type {
  PaginationProps,
  WithPaginationProps,
} from 'lib/dataLoader/pagination/types';

import { MessagingChatRoom, PaginatedCollection } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';

import {
  DataLoaderProvidedProps,
  newDataLoader,
  withPagination,
} from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  Button,
  DatatableWrapper,
  EmptyStateWithIcon,
  FetchContainer,
  SimpleTable,
  Text,
} from 'components';

import ChatRoomModal from '../Campaign/components/messaging/ChatRoomModal';

type Props = WithPaginationProps;

type AfterPaginateProps = Props & PaginationProps;

type AfterDataLoaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    messagingChatRoomsCollection: PaginatedCollection<MessagingChatRoom>;
  };

const MyList = ({
  messagingChatRoomsCollection,
  isFetching,
  hasError,
  page,
  countPerPage,
  search,
  onSearchChange,
}: AfterDataLoaderProps) => {
  const [selectedChatRoom, setSelectedChatRoom] =
    useState<MessagingChatRoom | null>(null);
  return (
    <Box
      additionalClassName={
        'survey-question-messaging-chat-rooms survey-box-question'
      }
    >
      <Text preset="18bs5" additionalClassName="block mb-4">
        {__('Anonymous conversation')}
      </Text>

      <DatatableWrapper
        collectionInfo={messagingChatRoomsCollection}
        isFetching={isFetching}
        hasError={hasError}
        showTotalRecordCount
        page={page}
        countPerPage={countPerPage}
        search={search}
        onSearchChange={onSearchChange}
        renderNoResult={() => (
          <EmptyStateWithIcon
            iconName="comment"
            title={__(
              "You don't have any follow-up anonymous conversations yet. They will be displayed here if an administrator wants to request more information regarding a comment you submitted in a survey."
            )}
          />
        )}
        totalCountRenderer={totalCount =>
          n__('%1 message', '%1 messages', totalCount || 0)
        }
      >
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="overlay"
          render={() => (
            <Fragment>
              <SimpleTable
                withBorder
                columns={[
                  {
                    header: __('Question'),
                    cell: chatRoom => (
                      <div>
                        <div>
                          <Text
                            preset="14bs6"
                            additionalClassName="text-text-main"
                          >
                            {chatRoom.campaignName}
                          </Text>
                        </div>
                        <div>
                          <Text
                            preset="13s7"
                            additionalClassName="text-text-light"
                          >
                            {chatRoom.questionName}
                          </Text>
                        </div>
                      </div>
                    ),
                  },
                  {
                    header: '',
                    cell: chatRoom => (
                      <div className="flex items-center">
                        <Text
                          preset="13s7"
                          additionalClassName="text-text-light"
                        >
                          {__(
                            'Last message created on %1',
                            moment(chatRoom.lastMessageAt).format(
                              'DD MMMM YYYY'
                            )
                          )}
                        </Text>
                        <Button
                          additionalClassName="ml-4"
                          color="secondary"
                          size="small"
                          onClick={() => setSelectedChatRoom(chatRoom)}
                        >
                          {__('Open the conversation')}
                        </Button>
                      </div>
                    ),
                  },
                ]}
                rows={messagingChatRoomsCollection?.items || []}
                keyFn={chatRoom => chatRoom.id}
                additionalClassName="mt-2 mb-4"
              />
              {selectedChatRoom && (
                <ChatRoomModal
                  answer={selectedChatRoom.messagingSource}
                  messagingChatRoom={selectedChatRoom}
                  participantId={selectedChatRoom.anonymousParticipantId}
                  onClose={() => setSelectedChatRoom(null)}
                />
              )}
            </Fragment>
          )}
        />
      </DatatableWrapper>
    </Box>
  );
};

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({ page, countPerPage }: AfterPaginateProps) =>
      get(`survey/messaging/my_chat_rooms`, {
        page,
        countPerPage,
      }),
    hydrate: {
      messagingChatRoomsCollection: { items: { messagingSource: {} } },
    },
    cacheKey: ({ page, countPerPage }: AfterPaginateProps) =>
      compositeKey({
        view: 'my-survey-messaging-chat-rooms-list',
        page,
        countPerPage,
      }),
  })
)(MyList);
