import React from 'react';

import type { FundingSource, ParticipantFundingItem } from 'models';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { Button, Column, Columns, Icon } from 'components';

import MoneyInput from 'scenes/admin/components/MoneyInput';

import FundingSourceSelect from './FundingSourceSelect';

type NewFundingItem = {
  amountCents: number | undefined | null;
  amountCurrency: string;
  fundingSource: FundingSource | undefined | null;
};

type Props = {
  fundingItem: ParticipantFundingItem | NewFundingItem;
  fundingSources: Array<FundingSource>;
  disabledFundingSources: Array<FundingSource>;
  onUpdate: (params: Partial<ParticipantFundingItem>) => void;
  onDelete: () => void;
};

const FundingItem = ({
  fundingItem,
  fundingSources,
  disabledFundingSources,
  onUpdate,
  onDelete,
}: Props) => {
  const handleUpdate = (
    newFundingItem: ParticipantFundingItem | NewFundingItem
  ) => {
    const fundingSource = newFundingItem.fundingSource;

    invariant(
      fundingSource,
      'fundingSource must be defined when updating fundingItem'
    );

    onUpdate({ ...newFundingItem, fundingSource });
  };

  const onAmountChange = (amountCents: number | null) => {
    const newFundingItem = { ...fundingItem };

    newFundingItem.amountCents = amountCents;

    handleUpdate(newFundingItem);
  };

  const onFundingSourceChange = (fundingSource: FundingSource) => {
    const newFundingItem = { ...fundingItem };

    newFundingItem.fundingSource = fundingSource;

    handleUpdate(newFundingItem);
  };

  return (
    <Columns contentVerticallyAligned additionalClassName="py-0 my-0">
      <Column additionalClassName="py-1" size={6}>
        <FundingSourceSelect
          fundingSource={fundingItem.fundingSource}
          onChange={onFundingSourceChange}
          fundingSources={fundingSources}
          disabledFundingSources={disabledFundingSources}
        />
      </Column>
      <Column additionalClassName="py-1 pr-2" size={5}>
        <MoneyInput
          testClassName="test-budget-item-money-input"
          amount={fundingItem.amountCents}
          placeholder={__('Amount')}
          currency={fundingItem.amountCurrency}
          onAmountChange={amountCents => {
            onAmountChange(amountCents);
          }}
          isDisabled={!fundingItem.fundingSource}
          saveOnlyOnBlur
        />
      </Column>
      <Column additionalClassName="py-1 pl-0 pr-2" size={1}>
        <Button
          testClassName="delete-funding-item-button"
          additionalClassName="delete-funding-item no-underline"
          isText
          onClick={onDelete}
        >
          <Icon size="small" name="close" />
        </Button>
      </Column>
    </Columns>
  );
};

export default FundingItem;
