import React, { Fragment, useState } from 'react';

import {
  SurveyAnswer,
  SurveyCampaign,
  SurveyQuestionStats,
  SurveyQuestionType,
} from 'models';

import { __ } from 'helpers/i18n';

import {
  BoxListItem,
  Button,
  Column,
  Columns,
  Date,
  FeatureFlagged,
  Text,
} from 'components';

import MessagingChatRoomModal from '../components/messaging/ChatRoomModal';
import AnswerScore from './AnswerScore';

type Props = {
  answer: SurveyAnswer;
  questionType: SurveyQuestionType;
  questionStats: SurveyQuestionStats;
  campaign: SurveyCampaign;
  showAnswerScore: boolean;
};

export default function AnswerItem({
  answer,
  questionStats,
  questionType,
  campaign,
  showAnswerScore,
}: Props) {
  const [displayMessagingModal, setDisplayMessagingModal] = useState(false);
  return (
    <Fragment>
      <BoxListItem additionalClassName="px-4">
        <Columns>
          <Column>
            {answer.text ? (
              <Text preset="14s6">{answer.text}</Text>
            ) : (
              <Text preset="14s6" color="light" transformation="italic">
                {__('Did not leave a comment')}
              </Text>
            )}
            <FeatureFlagged flag="surveyMessagingChatRooms">
              <div>
                <Text color="light" style={{ verticalAlign: 'sub' }}>
                  <Date
                    format="LL"
                    value={answer.submittedAt}
                    additionalClassName="mt-1"
                  />
                  <Button
                    color="secondary"
                    size="small"
                    onClick={() => setDisplayMessagingModal(true)}
                    additionalClassName="ml-2"
                  >
                    {answer.messagingChatRoom
                      ? __('Open the conversation')
                      : __('Start a conversation')}
                  </Button>
                </Text>
              </div>
            </FeatureFlagged>
          </Column>
          {showAnswerScore && (
            <AnswerScore answer={answer} questionType={questionType} />
          )}
        </Columns>
      </BoxListItem>
      {displayMessagingModal && (
        <MessagingChatRoomModal
          answer={answer}
          messagingChatRoom={answer.messagingChatRoom}
          questionName={
            answer.messagingChatRoom?.questionName ||
            `${questionStats.questionNumber} - ${questionStats.question.title}`
          }
          campaignName={answer.messagingChatRoom?.campaignName || campaign.name}
          onClose={() => setDisplayMessagingModal(false)}
        />
      )}
    </Fragment>
  );
}
