import React, { Fragment, useState } from 'react';

import type {
  SurveyAnswer,
  SurveyCampaign,
  SurveyCampaignPreview,
} from 'models';

import { isInstructionFormStep, isQuestionFormStep } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { pathToSurveysToComplete } from 'helpers/navigation';
import confirmAsync from 'helpers/react/confirmAsync';

import { post, put } from 'redux/actions/api';

import {
  Box,
  FetchContainer,
  Icon,
  PageTitle,
  Redirect,
  SavingStatusConnector,
} from 'components';
import AutoSaveButton from 'components/withSavingStatus/Button';

import campaignNameWithPeriod from '../helpers/campaignNameWithPeriod';
import SurveyPageHeader from './PageHeader';
import ThanksModal from './ThanksModal';
import Step from './steps';

const extractPeriodInfo = (
  campaign: SurveyCampaign | SurveyCampaignPreview
) => {
  if (campaign.preview) {
    return {
      form: campaign.form,
      pageTitle: campaignNameWithPeriod(campaign.name, null),
      daysUntilTheEnd: campaign.daysToComplete - 1,
      isCompleted: false,
      isPreview: true,
    };
  } else {
    const { currentPeriod } = campaign;
    invariant(currentPeriod, 'currentPeriod must be defined');
    const { form } = currentPeriod;
    invariant(form, 'form must be defined');
    return {
      form,
      pageTitle: campaignNameWithPeriod(campaign.name, currentPeriod.name),
      daysUntilTheEnd: currentPeriod.daysUntilTheEnd,
      isCompleted:
        currentPeriod.currentUserParticipation?.status === 'completed',
      isPreview: false,
    };
  }
};

const extractAnswers = (campaign: SurveyCampaign | SurveyCampaignPreview) => {
  if (campaign.preview) {
    return null;
  } else {
    const { currentPeriod } = campaign;
    const answers = currentPeriod?.currentUserParticipation?.answers;
    invariant(answers, 'answers must be defined');

    const answerByQuestionId: { [id: string]: SurveyAnswer } = answers.reduce(
      (map, answer) => {
        map[answer.questionId] = answer;
        return map;
      },
      {}
    );

    return answerByQuestionId;
  }
};

type Props = {
  isFetching: boolean;
  hasError: boolean;
  campaign: SurveyCampaign | SurveyCampaignPreview;
  isPreview?: boolean;
};

const SurveyContent = ({ isFetching, hasError, campaign }: Props) => {
  const dispatch = useAppDispatch();
  const [thanksModalVisible, setThanksModalVisible] = useState(false);

  if (!!campaign && !campaign.currentPeriod && campaign.status !== 'draft') {
    return <Redirect to={pathToSurveysToComplete()} />;
  }

  const updateAnswer = (id: string, params: Partial<SurveyAnswer>) =>
    dispatch(
      put(`survey/answers/${id}`, params, { withDefaultErrorHandling: false })
    );

  const submitSurvey = () =>
    confirmAsync(
      __('Send your response'),
      __('Once sent, your response will be locked.'),
      {
        confirmLabel: __('Send my response'),
        inlineButtonIcon: <Icon name="send" additionalClassName="mr-1" />,
        onConfirm: async () => {
          await dispatch(
            post(`survey/campaigns/${campaign.id}/my_ongoing_survey/submit`)
          );
          setThanksModalVisible(true);
        },
      }
    );

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        const { form, pageTitle, daysUntilTheEnd, isCompleted, isPreview } =
          extractPeriodInfo(campaign);

        const answerByQuestionId = extractAnswers(campaign);

        return (
          <Fragment>
            <SurveyPageHeader
              title={pageTitle}
              isAnonymous={campaign.anonymous}
              endDate={campaign.lastPeriodEndDate || ''}
              daysUntilTheEnd={daysUntilTheEnd}
              isCompleted={isCompleted}
              isPreview={isPreview}
            />
            <PageTitle title={pageTitle} />

            <div className="mt-8 max-w-[960px] mx-auto">
              {form.steps.map(formStep => {
                if (isQuestionFormStep(formStep)) {
                  const answer = answerByQuestionId
                    ? answerByQuestionId[formStep.question.id]
                    : null;

                  return (
                    <Step
                      key={formStep.id}
                      formStep={formStep}
                      onChange={params =>
                        answer ? updateAnswer(answer.id, params) : null
                      }
                      answer={answer}
                      isDisabled={isCompleted}
                    />
                  );
                }
                if (isInstructionFormStep(formStep)) {
                  return <Step key={formStep.id} formStep={formStep} />;
                }
                return null;
              })}
              <Box>
                <SavingStatusConnector
                  render={({ ...savingStatusArgs }) => (
                    <div className="flex justify-center gap-8">
                      <AutoSaveButton
                        {...savingStatusArgs}
                        color="secondary"
                        to={pathToSurveysToComplete()}
                        disabled={isPreview}
                      >
                        {isCompleted ? __('Close') : __('Close and send later')}
                      </AutoSaveButton>
                      <AutoSaveButton
                        {...savingStatusArgs}
                        color="primary"
                        onClick={submitSurvey}
                        disabled={isCompleted || isPreview}
                        disabledOnFailed
                      >
                        <Icon name="send" additionalClassName="mr-1" />
                        {__('Send')}
                      </AutoSaveButton>
                    </div>
                  )}
                />
              </Box>
            </div>
            {thanksModalVisible && <ThanksModal />}
          </Fragment>
        );
      }}
    />
  );
};

export default SurveyContent;
