import React, { Fragment } from 'react';

import can from 'helpers/can';
import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { navigate, pathToNewSurveyCampaign } from 'helpers/navigation';

import {
  Button,
  ContentContainer,
  Icon,
  PageHeader,
  PageTitle,
} from 'components';

import CampaignList from './CampaignList';

const Campaigns = () => {
  const CreateNewCampaign = (
    <Button
      key="create_campaign"
      color="primary"
      onClick={() => navigate(pathToNewSurveyCampaign())}
    >
      <Icon name="add" additionalClassName="mr-2" />
      {__('New campaign')}
    </Button>
  );

  const organization = useOrganization();
  const canCreateNewCampaign = can({
    perform: 'create_survey_campaigns',
    on: organization,
  });

  const actions = canCreateNewCampaign ? [CreateNewCampaign] : [];

  return (
    <Fragment>
      <PageTitle title={[__('Surveys'), __('Campaigns')]} />
      <PageHeader title={__('Survey campaigns')} actions={actions} />
      <ContentContainer>
        <CampaignList
          paginationType="url"
          defaultPaginationParams={{
            filter: { not_archived: true },
            sort: { createdAt: 'desc' },
          }}
        />
      </ContentContainer>
    </Fragment>
  );
};

export default Campaigns;
