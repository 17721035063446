import React, { Fragment, useState } from 'react';

import type { TrainingOverallBudgetStats } from 'models';

import can from 'helpers/can';
import { useAppDispatch, useOrganization } from 'helpers/hooks';

import BudgetDetailsModal from '../BudgetDetailsModal';
import BudgetManagerModal from '../BudgetManagerModal';
import { refetchSessionStats } from '../Sessions/SessionStats';
import BudgetCardWithStats from './BudgetCardWithStats';
import BudgetCreationCard from './BudgetCreationCard';

type Props = {
  periodSlug: string;
  overallStats: TrainingOverallBudgetStats;
};

const BudgetCard = ({ periodSlug, overallStats }: Props) => {
  const organization = useOrganization();
  const dispatch = useAppDispatch();
  const refetchStats = (periodSlug: string) =>
    dispatch(refetchSessionStats(periodSlug));
  const [budgetManagerModalActive, setBudgetManagerModalActive] =
    useState(false);
  const [budgetDetailsModalActive, setBudgetDetailsModalActive] =
    useState(false);

  const canManageBudget = can({ perform: 'manage_budget', on: organization });
  const undefinedBudget =
    overallStats.budgetItemsCount === 0 && overallStats.fundingItemsCount === 0;
  const canDefineBudget = undefinedBudget && canManageBudget;

  return (
    <Fragment>
      {canDefineBudget && (
        <BudgetCreationCard onClick={() => setBudgetManagerModalActive(true)} />
      )}

      {!undefinedBudget && (
        <BudgetCardWithStats
          stats={overallStats}
          onClick={() => setBudgetDetailsModalActive(true)}
        />
      )}
      {canManageBudget && budgetManagerModalActive && (
        <BudgetManagerModal
          periodSlug={periodSlug}
          isActive
          onClose={() => {
            setBudgetManagerModalActive(false);
            refetchStats(periodSlug);
          }}
        />
      )}

      {budgetDetailsModalActive && (
        <BudgetDetailsModal
          isActive
          canManageBudget={canManageBudget}
          periodSlug={periodSlug}
          overallStats={overallStats}
          onOpenBudgetManagementModal={() => setBudgetManagerModalActive(true)}
          onClose={() => {
            setBudgetDetailsModalActive(false);
          }}
        />
      )}
    </Fragment>
  );
};

export default BudgetCard;
